<template>
    <section class="blockElement space innerMenu settings">
        <div class="container-fluid">
            <!-- Personal Details Tab Start Here -->
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div class="verticalTabs">
                        <ul class="mobVerticleTabs">
                            <li>
                                <router-link :to="{name : 'dashboardinfo'}" class="d-flex p-lg-3 p-2 rounded align-items-center" @click="changePath('personal-info')" :class="[{'active' : $route.name=='dashboardinfo'}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon personal_details"></span>
                                    </span>

                                    <span class="f-16 bold mb-0">{{$t('dashboard.text1')}}</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name : 'tradingAccounts'}" class="d-flex p-lg-3 p-2 rounded align-items-center" @click="changePath('trading-accounts')" :class="[{'active' : $route.name=='tradingAccounts'},{'disabled' : !store.customerDetail.completed}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon trading_accounts"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('dashboard.text2')}}</span>
                               </router-link>
                            </li>
                            <!-- <li>
                                <router-link :to="{name : 'subscription'}" class="d-flex p-lg-3 p-2 rounded align-items-center" @click="changePath('subscription')" :class="[{'active' : $route.name=='subscription'},{'disabled' : !store.customerDetail.completed || store.customerDetail.requiredActions || !COMMON.liveAccounts()}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon subscription_plan"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('dashboard.text3')}}</span>
                               </router-link>
                            </li> -->
                            <li>
                                <router-link :to="{name : 'myPosts'}" class="d-flex p-3 rounded align-items-center" @click="changePath('my-posts')" :class="[{'active' : $route.name=='myPosts'}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon myPostp"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{(Object.keys(store.userDetail).length && store.userDetail.firstName)? store.userDetail.firstName : $t('dashboard.text4')}} {{$t('dashboard.text5')}}</span>
                               </router-link>
                            </li>
                            <li v-if="store.isLeaderList.length">
                                  <router-link :to="{name : 'leaderWidget'}" class="d-flex p-3 rounded align-items-center" @click="changePath('leader-widget')" :class="[{'active' : $route.name=='leaderWidget'}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon need_help"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('head.text25')}}</span>
                               </router-link>
                            </li>
                            <li>
                                <router-link :to="{name : 'changePassword'}" class="d-flex p-3 rounded align-items-center" @click="changePath('change-password')" :class="[{'active' : $route.name=='changePassword'}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon myPassword"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('dashboard.text6')}}</span>
                               </router-link>
                            </li>
                            <!-- Trade History start -->
                            <li>
                                  <router-link :to="{name : 'tradeHistory'}" @click="changePath('trade-history')" class="d-flex p-3 rounded align-items-center"  :class="[{'active' : $route.name=='tradeHistory'}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon trading_history"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('head.text26')}}</span>
                               </router-link>
                            </li>
                            <!-- Trade History end -->

                            <!-- Account Setting start -->
                            <li>
                                  <router-link :to="{name : 'accountSetting'}" @click="changePath('account')" class="d-flex p-3 rounded align-items-center"  :class="[{'active' : $route.name=='accountSetting'}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon account_settings"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('head.text27')}}</span>
                               </router-link>
                            </li>
                            <!-- Account Setting end -->
                            <li>
                                <router-link :to="{name : 'notificationSetting'}" class="d-flex p-3 rounded align-items-center" @click="changePath('notifications')" :class="[{'active' : $route.name=='notificationSetting'}]">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="maskedIcon settings"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('changes.text34')}}</span>
                               </router-link>
                            </li>
                            <li v-if="store.questionariesStatus?.status == 'SUBMITTED'" :class="{'disabled' : store.customerDetail?.readOnly}">
                                <a :href="'/suitability'" class="d-flex p-3 rounded align-items-center">
                                    <span class="me-lg-3 me-1 d-flex">
                                        <span class="suitabilityTest maskedIcon"></span>
                                    </span>
                                    <span class="f-16 bold mb-0">{{$t('changes.text35')}}</span>
                               </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Personal Details Tab Start Here -->
                <personalDetail v-if="$route.name=='dashboardinfo'"/>
                <!-- Personal Details Tab End Here -->

                <!-- Trading Accounts Tab Start Here -->
                <tradingAccounts v-if="$route.name=='tradingAccounts'" />
                <!-- Trading Accounts Tab End Here -->

                <!-- Subscription Plan Tab Start Here -->
                <!-- <subscriptionPlan v-if="$route.name=='subscription'" /> -->
                <!-- Subscription Plan Tab End Here -->
                <myPosts v-if="$route.name=='myPosts'" />
                <changePassword v-if="$route.name=='changePassword'" />
                <needHelp v-if="$route.name=='needHelp'" />
                <leaderWidget v-if="$route.name=='leaderWidget'" />
                <notifications v-if="$route.name=='notificationSetting'" />
                <accountSetting v-if="$route.name=='accountSetting'" />
                <!-- Trade History start -->
                <tradeHistory v-if="$route.name=='tradeHistory'" />
                <!-- Trade History end -->
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    // import settings from "./pages/settings";
    import personalDetail from "./pages/personal-detail";
    import tradingAccounts from "./pages/trading-accounts";
    // import subscriptionPlan from "./pages/subscription-plan";
    import needHelp from "./pages/need-help";
    import myPosts from "./pages/my-feed";
    import changePassword from "./pages/change-password";
    import leaderWidget from "./pages/leader-widget";
    import notifications from "./pages/settings.vue";
    import accountSetting from "./pages/account-settings.vue";
    import tradeHistory from "./pages/trade-history.vue";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                tab: 1,
            };
        },
        components: {
            personalDetail,
            tradingAccounts,
            // subscriptionPlan,
            needHelp,
            myPosts,
            changePassword,
            leaderWidget,
            notifications,
            accountSetting,
            tradeHistory,
        },
        methods:{
            changePath(path){
                if(path){
                    this.$router.replace(`/dashboard/${path}`)
                }
            },
        },
        created(){
            if(this.$route.path != '/dashboard/personal-info'){
                this.$router.replace(this.$route.path)
            }else{
                this.changePath('personal-info')
            }
        //   console.log('==>',window.localStorage)
        }
    };
</script>
